import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Keycloak from 'keycloak-js'
import api from './modules/api'
import VueHtml2Canvas from "vue-html2canvas/src";

Vue.config.productionTip = true

Vue.use(VueHtml2Canvas);
Vue.prototype.$backendUrl =  window.VUE_APP_MAIN_BACKEND_URL
api.backendUrl =  window.VUE_APP_MAIN_BACKEND_URL

let initOptions = {
  url:  window.VUE_APP_KEYCLOAK_URL,
  realm:  window.VUE_APP_KEYCLOAK_REALM,
  clientId:  window.VUE_APP_KEYCLOAK_CLIENT_ID,
  onLoad: 'login-required',
}

let keycloak = Keycloak(initOptions)
Vue.prototype.$keycloak = keycloak
Vue.prototype.$vcotUser = false

function updateHeaders () {
  const authHeaders = new Headers()
  authHeaders.append('Authorization', `Bearer ${keycloak.token}`)
  const postHeaders = new Headers()
  postHeaders.append('Authorization', `Bearer ${keycloak.token}`)
  //postHeaders.append('Accept', 'application/json')
  postHeaders.append('Content-Type', 'application/json')
  const postFormDataHeaders = new Headers()
  postFormDataHeaders.append('Authorization', `Bearer ${keycloak.token}`)
  Vue.prototype.$authHeaders = authHeaders
  Vue.prototype.$postHeaders = postHeaders
  Vue.prototype.$postFormDataHeaders = postFormDataHeaders
}

Vue.prototype.$setBehalfUser = function(user){
  Vue.prototype.$behalfUser = user
}

Vue.prototype.$getUserByName = function(username){
  if(this.$user.username === username)
    return this.$user
  for(let u of Vue.prototype.$cznDepartmentsList){
    if(u.username === username){
      return u
    }
  }
}

keycloak.init({ onLoad: initOptions.onLoad }).then(async (auth) => {
  if (!auth) {
    window.location.reload()
  } else {
    api.token = keycloak.token
    updateHeaders()

    Vue.prototype.$serverDateTime = new Date(localStorage.getItem('lastServerDateTime') || '2024-01-01')
    api.get('/config/date').then(res=>{
      if(res.ok){
        Vue.prototype.$serverDateTime = new Date(res.payload)
        localStorage.setItem('lastServerDateTime', res.payload)
      }
    })

    // чтобы keycloak отдавал роли, нужно для клиента настроить mappers
    let role,
        name = keycloak.idTokenParsed.name,
        username = keycloak.idTokenParsed.preferred_username,
        subroles=keycloak.realmAccess.roles
    if (keycloak.realmAccess.roles.includes('ROLE_ADMIN')) {
      role = 'ROLE_ADMIN'
    } else if (keycloak.realmAccess.roles.includes('ROLE_VNII')) {
      role = 'ROLE_VNII'
      if(username.includes("@vcot.info")){
        Vue.prototype.$vcotUser = true
      }
    } else if (keycloak.realmAccess.roles.includes('ROLE_CZN_MANAGER')) {
      role = 'ROLE_CZN_MANAGER'
    } else if (keycloak.realmAccess.roles.includes('ROLE_CZN_TERRITORIAL')) {
      role = 'ROLE_CZN_TERRITORIAL'
    } else if (keycloak.realmAccess.roles.includes('ROLE_REGIONAL_AGENCY')) {
      role = 'ROLE_REGIONAL_AGENCY'
    } else if (keycloak.realmAccess.roles.includes('ROLE_REGIONAL_ADMINISTRATOR')) {
      role = 'ROLE_REGIONAL_ADMINISTRATOR'
    } else if (keycloak.realmAccess.roles.includes('ROLE_MINTRUD')) {
      role = 'ROLE_MINTRUD'
    } else {
      alert('Ошибка авторизации: неизвестная роль')
      return
    }

    await initializeDepartments()

    //формирование атрибутов юзера
    Vue.prototype.$user = {
      username: username,
      name: name,
      role: role,
      subroles: subroles,
    }
    console.log(Vue.prototype.$user)
    if (role === 'ROLE_CZN_MANAGER' || role === 'ROLE_CZN_TERRITORIAL') {
      Vue.prototype.$user.department = Vue.prototype.$getDepartmentById(keycloak.idTokenParsed.department.id)
      Vue.prototype.$user.region = Vue.prototype.$user.department.region
    } else if (role === 'ROLE_REGIONAL_AGENCY' || role === 'ROLE_REGIONAL_ADMINISTRATOR') {
      Vue.prototype.$user.region = {
        code: keycloak.idTokenParsed.region_code,
        name: keycloak.idTokenParsed.region_name,
        netType: keycloak.idTokenParsed.region_net_type
      };
    }
    console.log ('$user', Vue.prototype.$user)


    /* eslint-disable no-new */
    new Vue({
      el: '#app',
      render: h => h(App, {props: {keycloak: keycloak}}),
      vuetify,
      router
    })
  }

  // Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        api.token = keycloak.token;
        updateHeaders()
      } else {
        // console.log('Token not refreshed, valid for ' + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
      }
    }).catch(() => {
      console.log('Failed to refresh token')
    })
  }, 6000)
}).catch((e) => {
  console.log('Authentication Failed: ' + e)
})

// import YmapPlugin from 'vue-yandex-maps'
// const settings = {
//   apiKey: '90a80d24-0d3c-4471-b98d-2acf543bc522',
//   lang: 'ru_RU',
//   coordorder: 'latlong',
//   enterprise: false,
//   version: '2.1',
// }
//
// Vue.use(YmapPlugin, settings)


function clean (obj) {
  for (let propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

Vue.prototype.$getApi = async function (path, querySpec) {
  if (path[0] !== '/') {
    path = '/' + path
  }
  const url = new URL(this.$backendUrl + path)
  if (querySpec != null) {
    url.search = new URLSearchParams(clean(querySpec)).toString()
  }
  try {
    let req = await fetch(url.toString(), {method: 'GET', headers: this.$authHeaders})
    if (req.ok) {
      return {ok: true, payload: await tryGetJson(req)}
    } else {
      // console.log(req)
      return {ok: false, payload: null, error: req.err}
    }
  } catch (e) {
    console.log(e)
    return {ok: false, error: e}
  }
}

Vue.prototype.$postApi = async function (path, obj) {
  if (path[0] !== '/') {
    path = '/' + path
  }
  const url = new URL(this.$backendUrl + path)
  try {
    let req = await fetch(url.toString(), {method: 'POST', headers: this.$postHeaders, body: JSON.stringify(obj)})
    // console.log(req)
    if (req.ok) {
      return {ok: true, payload: await tryGetJson(req)}
    } else {
      return {ok: false, payload: null, error: req.err ? req.err : await req.text()}
    }
  } catch (e) {
    console.log(e)
    return {ok: false, error: e}
  }
}

Vue.prototype.$postFormDataApi = async function (path, formData) {
  if (path[0] !== '/') {
    path = '/' + path
  }

  //На вход удобно также получать не форм дата, а объект
  if(!(formData instanceof FormData)){
    let correctFormData = new FormData();

    for (let key in formData) {
      correctFormData.append(key, formData[key]);
    }
    formData = correctFormData
  }

  const url = new URL(this.$backendUrl + path)
  let req = await fetch(url.toString(), {method: 'POST', headers: this.$postFormDataHeaders, body: formData})
  if (req.ok) {
    return {ok: true, payload: await tryGetJson(req)}
  } else {
    // console.log(req)
    return {ok: false, payload: null}
  }
}

async function tryGetJson(req){
  let result = null
  try{
    result = await req.json()
  } catch (e){
    result = null
  }
  return result
}


async function downloadFie (fileName, url, method, headers, body) {
  let req = await fetch(url.toString(), {method: method, headers: headers, body: body})
  if (!req.ok) {
    alert('Ошибка скачивания файла!')
    console.log(req)
  }
  let urln = URL.createObjectURL(await req.blob())
  const a = document.createElement('a')
  a.download = fileName
  a.style.display = 'none'
  a.href = urln
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(urln)
}

Vue.prototype.$downloadBlob = async function (blob, fileName) {
  let url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.download = fileName
  a.style.display = 'none'
  a.href = url
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
}

Vue.prototype.$downloadApi = async function(fileName, path, querySpec, isJsonBody) {
  if (path[0] !== '/') {
    path = '/' + path
  }
  const url = new URL(this.$backendUrl + path)
  if(isJsonBody === true) {
    await downloadFie(fileName, url.toString(), "POST", this.$postHeaders, JSON.stringify(querySpec))
  } else {
    if (querySpec != null) {
      url.search = new URLSearchParams(clean(querySpec)).toString()
    }
    await downloadFie(fileName, url, "GET", this.$authHeaders)
  }
}

/**
 * Cached NSI data
 * @typedef {Object} CacheFromLocalStorage
 * @property {string} actualityDateTime - ISO time when nsi was updated (returned form server)
 * @property {Object} departmentsList - cached departments list
 */


/**
 *
 * @returns {Promise<CacheFromLocalStorage>}
 */
async function getNsiUsingCacheFromLocalStorage(){
  let cacheFromLocalStorage = localStorage.getItem('nsiCache')
  if(cacheFromLocalStorage) {
    cacheFromLocalStorage = JSON.parse(cacheFromLocalStorage)
  }
  let req = await Vue.prototype.$getApi("/nsiinfo/actualdate");
  if(!req.ok) {
    alert("Ошибка получения данных")
    return
  }
  const actualCacheDateStr = req.payload
  if(cacheFromLocalStorage == null || actualCacheDateStr !== cacheFromLocalStorage.actualityDateStr){
    cacheFromLocalStorage = await setNsiInLocalStorageCache(actualCacheDateStr)
  }
  return cacheFromLocalStorage
}

async function setNsiInLocalStorageCache(actualityDateStr){
  let reqDepartments = await Vue.prototype.$getApi("/department/getList");
  if(!reqDepartments.ok) {
    alert("Ошибка получения данных")
    return
  }
  let departmentsList = reqDepartments.payload;
  const cacheFromLocalStorage = {
    actualityDateStr,
    departmentsList
  }
  localStorage.setItem('nsiCache', JSON.stringify(cacheFromLocalStorage))
  return cacheFromLocalStorage
}

async function initializeDepartments(){
  const nsiData = await getNsiUsingCacheFromLocalStorage()
  let departmentsList = nsiData.departmentsList
  //Сортировка департаментов по регионам
  let map = new Map(),
      departmentsByRegion;
  for (let dep of departmentsList) {
    if (!map.has(dep.region.code)) {
      map.set(dep.region.code, {
        name: dep.region.name,
        code: dep.region.code,
        year: dep.region.year,
        netType: dep.region.netType,
        departments: [dep]
      })
    } else {
      map.get(dep.region.code).departments.push(dep)
    }
  }
  departmentsByRegion = Array.from(map.values());
  // departmentsByRegion = Object.fromEntries(map.values());
  departmentsByRegion.sort((a, b) => (a.name > b.name ? 1 : -1));
  for (let dep of departmentsByRegion) {
    dep.departments.sort((a, b) => (a.name > b.name ? 1 : -1))
  }

  Vue.prototype.$cznDepartmentsList = departmentsList
  const netTypes = {
    'CENTRALIZED': 'централизованный',
    'DECENTRALIZED': 'децентрализованный'
  }
  departmentsByRegion.map(e => e.nameWithNetType=e.name + ' (' + (netTypes[e.netType]) + ')')
  Vue.prototype.$cznDepartmentsByRegionList = departmentsByRegion
}

Vue.prototype.$getDepartmentById = function(id){
  return Vue.prototype.$cznDepartmentsList.find(e => e.id === +id)
}

Vue.prototype.$getRegionById = function(id){
  return Vue.prototype.$cznDepartmentsList.find(e => e.id === +id);
}

Vue.prototype.$initializeDepartments = initializeDepartments;


const regionAndDepartmentInfo = Vue.observable({value: {region: null, department: null}})
Object.defineProperty(Vue.prototype, '$regionAndDepartmentInfo', {
  get () {
    return regionAndDepartmentInfo.value
  },

  set (value) {
    regionAndDepartmentInfo.value = value
  }
})
